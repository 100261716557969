/* Scrollbar on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: rgba(100, 116, 139, .5) rgba(100, 116, 139, .16);
}

/* Scrollbar on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 0.375rem !important;
    background-color: transparent;
}

*::-webkit-scrollbar-track {
    background-color: rgba(100, 116, 139, .16) !important;
    border-radius: 0.25rem!important;
}

*::-webkit-scrollbar-thumb {
    background-color: rgba(100, 116, 139, .5) !important;
    border-radius: 0.25rem !important;
}